import TestimonalSlider from './TestimonialSider'

const Testimonial = () => {
  return (
    <div className='w-full h-auto md:h-[450px] bg-white flex flex-col my-5 md:my-5 px-4 py-2'>
    <h3 className='text-xl md:text-3xl font-bold text-center text-black mt-3'>TESTIMONIALS SPOTLIGHT </h3>
    <TestimonalSlider />
    </div>
  )
}

export default Testimonial