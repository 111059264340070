import LOCAL_IMAGES from "./localImages";
import { FaRegHourglassHalf } from "react-icons/fa6";
export const citiesJson = [
  {
    name: "Andhra Pradesh",
  },
  {
    name: "Arunachal Pradesh",
  },
  {
    name: "Assam",
  },
  {
    name: "Bihar",
  },
  {
    name: "Chhattisgarh",
  },
  {
    name: "Goa",
  },
  {
    name: "Gujarat",
  },
  {
    name: "Haryana",
  },
  {
    name: "Himachal Pradesh",
  },
  {
    name: "Jharkhand",
  },
  {
    name: "Karnataka",
  },
  {
    name: "Kerala",
  },
  {
    name: "Madhya Pradesh",
  },
  {
    name: "Maharashtra",
  },
  {
    name: "Manipur",
  },
  {
    name: "Meghalaya",
  },
  {
    name: "Mizoram",
  },
  {
    name: "Nagaland",
  },
  {
    name: "Odisha",
  },
  {
    name: "Punjab",
  },
  {
    name: "Rajasthan",
  },
  {
    name: "Sikkim",
  },
  {
    name: "Tamil Nadu",
  },
  {
    name: "Telangana",
  },
  {
    name: "Tripura",
  },
  {
    name: "Uttar Pradesh",
  },
  {
    name: "Uttarakhand",
  },
  {
    name: "West Bengal",
  },
  {
    name: "Andaman and Nicobar Islands",
  },
  {
    name: "Chandigarh",
  },
  {
    name: "Dadra and Nagar Haveli and Daman and Diu",
  },
  {
    name: "Lakshadweep",
  },
  {
    name: "Delhi",
  },
  {
    name: "Puducherry",
  },
  {
    name: "Ladakh",
  },
  {
    name: "Lakshadweep",
  },
];
export const OurServicesData = [
  {
    serviceIcon: LOCAL_IMAGES.hairIcon,
    serviceName: "Hair",
    serviceDiscipton:
      "Experience professional hair-care as some of the best in the business provide you with the cutting-edge treatments",
  },
  {
    serviceIcon: LOCAL_IMAGES.beautyIcon,
    serviceName: "Beauty",
    serviceDiscipton:
      "Is the pollution making your face dull ? No need to worry- Our beauty professionals will work their charm and have you looking fresh ",
  },
  {
    serviceIcon: LOCAL_IMAGES.makeupIcon,
    serviceName: "Makeup",
    serviceDiscipton:
      " Step into a world of makeovers as our trained experts use the best beauty products to transform you into a modern day diva",
  },
  {
    serviceIcon: LOCAL_IMAGES.nailIcon,
    serviceName: "Nail",
    serviceDiscipton:
      "  Why stop at hair and makeup when our experienced artists can give your fingertips the care they have always deserved",
  },
];

export const franchiseBestData = [
  {
    discrption:
      "30 days of rollout and salon setup whole turnkey assistance,from select location to salon operations",
  },
  {
    discrption:
      "Support during pre-launch, launch, and post-launch for smooth operations.",
  },
  {
    discrption: "Complete transparency, ethical business practices",
  },
  {
    discrption:
      "Experience luxury on a budget at our affordable salon, where top-quality beauty services meet unbeatable prices. Visit us today!.",
  },
  {
    discrption:
      "Our support includes Talent Acquisition, Training, Operational Strategies, Business Intelligence, Public Communication, and Customer Care Assistance throughout our partnership.",
  },
  {
    discrption:
      "Assistance to maintain Customer Experience Consistency throughout all salons.",
  },
  {
    discrption:
      "Discover the cheapest salon in India for quality beauty services that fit your budget without compromising on style and care.",
  },
];
export const salonFranchiseDiscription = [
  {
    image: <FaRegHourglassHalf />,
    heading: "Multi-awarded nationally",
    subHeading: "3 decades of service excellence",
  },
  {
    image: LOCAL_IMAGES.calenderIcon,
    heading: "230+ locations",
    subHeading: "Two unique business solutions: Salon & Studio",
  },
  {
    image: LOCAL_IMAGES.calenderIcon,
    heading: "3000+ Artists & Technicians",
    subHeading: "Globally recognised products and services",
  },
  {
    image: LOCAL_IMAGES.calenderIcon,
    heading: "35+ Cities Pan-India",
    subHeading: "Continued Business Support Operations, HR, Marketing",
  },
];

export const brandsImg = [
  {
    brandUrl: LOCAL_IMAGES.argatinIcon,
  },
  {
    brandUrl: LOCAL_IMAGES.springIcon,
  },

  {
    brandUrl: LOCAL_IMAGES.skinIcon,
  },
  {
    brandUrl: LOCAL_IMAGES.thalgoIcon,
  },
  {
    brandUrl: LOCAL_IMAGES.lorealIcon,
  },
  {
    brandUrl: LOCAL_IMAGES.kerastaseIcon,
  },
];

export const galleryImagesData = [
  {
    images: [
      {
        galleryimg: LOCAL_IMAGES.monsoonsalon12,
      },
      {
        galleryimg: LOCAL_IMAGES.monsoonsalon13,
      },

      {
        galleryimg: LOCAL_IMAGES.monsoonsalon14,
      },
      {
        galleryimg: LOCAL_IMAGES.monsoonsalon21,
      },
    ],
  },
  {
    images: [
      {
        galleryimg: LOCAL_IMAGES.monsoonsalon22,
      },
      {
        galleryimg: LOCAL_IMAGES.monsoonsalon23,
      },
      {
        galleryimg: LOCAL_IMAGES.monsoonsalon24,
      },
      {
        galleryimg: LOCAL_IMAGES.monsoonsalon25,
      },
    ],
  },
  {
    images: [
      {
        galleryimg: LOCAL_IMAGES.monsoonsalon15,
      },
      {
        galleryimg: LOCAL_IMAGES.monsoonsalon16,
      },

      {
        galleryimg: LOCAL_IMAGES.monsoonsalon17,
      },
      {
        galleryimg: LOCAL_IMAGES.monsoonsalon26,
      },
    ],
  },
  {
    images: [
      {
        galleryimg: LOCAL_IMAGES.monsoonsalon18,
      },
      {
        galleryimg: LOCAL_IMAGES.monsoonsalon19,
      },
      {
        galleryimg: LOCAL_IMAGES.monsoonsalon20,
      },
      {
        galleryimg: LOCAL_IMAGES.monsoonsalon27,
      },
    ],
  },

  // {
  //   galleryimg: LOCAL_IMAGES.monsoonsalon27,
  // },
];

export const statesOfIndia = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
  "Ladakh",
  "Jammu and Kashmir",
];
