const CustomTextArea = ({
  error = null,
  helperText = null,
  required = false,
  type = "text",
  value,
  onChange,
  label,
  name,
}) => {
  return (
    <>
      <div
        className={`relative   col-span-full
           my-1
        `}
      >
        <textarea
          id={name}
          rows={3}
          cols={30}
          className={`block  h-full resize-none bg-white active:bg-white ${error?"border-red-500":"border-regel-gray/40"} focus:bg-white  px-[14px] py-[8.5px] w-full text-sm text-gray-900 bg-transparent border  rounded-[4px] appearance-none  focus:ring-0 focus:outline-2 outline-offset-[-1px] focus:outline-navyblue focus:border-navyblue peer`}
          name={name}
          placeholder=" "
          value={value}
          onChange={onChange}
        />
        <label
          htmlFor={name}
          className={`absolute text-sm ${
            error ? "text-red-500" : "text-regel-gray"
          } duration-300 transform scale-100 top-1/2 z-1 origin-[0] bg-white  px-2 
    peer-focus:px-2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4
    ${
      value
        ? "top-2 scale-75 -translate-y-4"
        : "peer-placeholder-shown:top-[30%] peer-placeholder-shown:scale-100"
    }
    rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}
        >
          {label} {required && <sup className="text-sm -mt-3">*</sup>}
        </label>
        {error && (
          <p className="my-1 text-xs text-red-500 ">
            <span className="font-medium">{helperText}</span>
          </p>
        )}
      </div>
    </>
  );
};

export default CustomTextArea;
