import Slider from "react-slick";
import TestimonialPrevArrow from "./arrows/TestimonialPrevArrow";
import TestimonialNextArrow from "./arrows/TestimonialNextArrow";
import Message from "./Message";

function TestimonalSlider() {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 6000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    cssEase: "linear",
    prevArrow: <TestimonialPrevArrow />,
    nextArrow: <TestimonialNextArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 941,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slider-container relative my-10 md:my-10 lg:my-6 mx-8 md:px-5 lg:px-10 lg:py-10  h-full ">
      <Slider {...settings}>
        {/* {
        data?.map((item,index)=>(
            <ProductCart key={index} item={item} img={item.images[0]} name={item.name} desc={item.description} price={item.price} mrp={item.mrp} discount={Math.ceil(((item.mrp-item.price)/item.mrp)*100)} productType={"combos"}/>
        ))
      } */}
        <Message
          // img={LOCAL_IMAGES.Avatar}
          name="Arpita Solanki"
          desc={
            "I brought my daughter to smart salon for her first haircut, and the experience was wonderful.The stylist was patient and gentle,and they made it a memorable and positive experience for her."
          }
        />
        <Message
          // img={LOCAL_IMAGES.Avatar}
          name="Roshni panchal"
          desc={
            "I've been to many salons in the past, but smart Salon stands out for its professionalism and attention to detail. The quality of service and products they use is top-notch. I won't go anywhere else."
          }
        />
        <Message
          // img={LOCAL_IMAGES.Avatar}
          name="Priyanka Praksh"
          desc={
            "I was nervous about trying a new salon, but Smart Salon exceeded all my expectations. The staff is knowledgeable about the latest trends and techniques, and they made me feel comfortable and valued as a customer."
          }
        />
        <Message
          // img={LOCAL_IMAGES.Avatar}
          name="Priya Singh"
          desc={
            "As someone who's always struggled with hair color, I was nervous about trying something new. But Smart Salon team of experts put me at ease. My hair has never looked so beautiful and vibrant!"
          }
        />
        {/* <Message name="Chestha Bisht" desc={"This is one of the best body sunscreen I have used till date!  It absorbs real good into the skin and also it's really hydrating & leaves absolutely no white cast."}/> */}
      </Slider>
    </div>
  );
}

export default TestimonalSlider;
