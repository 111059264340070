import './maintext.css'
export default function MainText({textdata}) {
  return (
    <>
<div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
    <h1 className='aboutcontainertext' style={{marginBottom: '0px'}}>{textdata}</h1>
    {/* <img 
        src={LOCAL_IMAGES.lineIcon} 
        alt=''
        style={{marginTop: '5px'}}
    /> */}
</div>
    </>

  )
}
