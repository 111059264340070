import { Helmet } from "react-helmet";
import NewFooter from "../footer";
import { useState } from "react";

const Investors = () => {
  const [activeTab, setActiveTab] = useState(null);
  
  const accordionData = [
    {
      title: "IPO",
      details:
        "Details related to Initial Public Offerings (IPO) and the associated filings.",
      links: [
        { name: "Prospectus", to: "/prospectus" },
        { name: "RHP", to: "/rhp" },
        { name: "DRHP", to: "/drhp" },
      ],
    },
    {
      title: "Annual Reports",
      details:
        "A comprehensive overview of the company’s performance, financials, and other important metrics in the annual report.",
      links: [
        { name: "Annual Report FY 2021", to: "/annual-report-fy2021" },
        { name: "Annual Report FY 2022", to: "/annual-report-fy2022" },
        { name: "Annual Report FY 2023", to: "/annual-report-fy2023" },
        { name: "Annual Report FY 2024", to: "/annual-report-fy2024" },
      ],
    },
    {
      title: "Annual Returns",
      details:
        "Information regarding the annual returns filed with regulatory authorities, summarizing financial and operational data.",
      links: [
        { name: "Annual Return FY 2021", to: "/annual-return-fy2021" },
        { name: "Annual Return FY 2022", to: "/annual-return-fy2022" },
        { name: "Annual Return FY 2023", to: "/annual-return-fy2023" },
      ],
    },
    {
      title: "Board & Management",
      details:
        "Insights into the composition of the board of directors and management team, including their roles and responsibilities.",
      links: [
        { name: "Board of Directors", to: "/board-of-directors" },
        { name: "Details of Committee", to: "/details-of-committee" },
      ],
    },
    {
      title: "Board Meetings Outcome",
      details:
        "Summaries and outcomes of key discussions and decisions made during board meetings.",
      links: [
        {
          name: "Outcome of Board Meeting - 20th May, 2024",
          to: "/outcome-board-meeting-20240520",
        },
      ],
    },
    {
      title: "Preferential Issue",
      details:
        "Details on any preferential share issuance, including terms, purpose, and recipients.",
      links: [
        { name: "Signed Valuation Report", to: "/signed-valuation-report" },
      ],
    },
    {
      title: "Investors Grievance",
      details:
        "Information on how investors can raise grievances and the company’s procedures for addressing them.",
      links: [
        { name: "CS Certificate", to: "/cs-certificate" },
        { name: "CS Certificate - 30 Sept", to: "/cs-certificate-30sept" },
        { name: "Contact", to: "/contact" },
      ],
    },
    {
      title: "Policies",
      details:
        "Company policies that govern operations, ethical practices, and compliance standards.",
      links: [
        { name: "Code of Conduct - Board", to: "/code-of-conduct-board" },
        {
          name: "Code of Conduct - Independent Director",
          to: "/code-of-conduct-independent-director",
        },
        {
          name: "Nomination, Remuneration, and Evaluation Policy",
          to: "/nomination-remuneration-evaluation-policy",
        },
        { name: "Risk Management Policy", to: "/risk-management-policy" },
        { name: "Code of Conduct - Insider", to: "/code-of-conduct-insider" },
        {
          name: "Code of Fair Disclosure - Insider",
          to: "/code-of-fair-disclosure-insider",
        },
        {
          name: "Policy on Familiarisation for Independent Directors",
          to: "/policy-on-familiarisation-independent-directors",
        },
        {
          name: "Policy on Preservation of Documents",
          to: "/policy-on-preservation-documents",
        },
        { name: "Policy on Board Diversity", to: "/policy-on-board-diversity" },
        { name: "Policy on Vigil Mechanism", to: "/policy-on-vigil-mechanism" },
        {
          name: "Policy on Determining Materiality",
          to: "/policy-on-determining-materiality",
        },
        { name: "Archival Policy", to: "/archival-policy" },
        {
          name: "Related Party Transactions",
          to: "/related-party-transactions",
        },
        { name: "Sexual Harassment Policy", to: "/sexual-harassment-policy" },
      ],
    },
    {
      title: "T&C - Appointment of Independent Director",
      details:
        "Terms and conditions regarding the appointment of independent directors as per regulatory guidelines.",
      links: [{ name: "Terms & Conditions", to: "/terms-conditions" }],
    },
  ];
  console.log(activeTab,"activeTab")

  return (
    <>
      <Helmet>
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-KDRLNKTM');
          `}
        </script>

        {/* Google Tag Manager (noscript) */}
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KDRLNKTM"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript>
      </Helmet>
      <div className="flex items-center py-9 justify-center">
        <h4 className="font-semibold text-3xl"> Investors Relations</h4>
      </div>

      <ul className="w-[90%] md:w-[60%] mt-9 mb-16 mx-auto rounded-md">
        {accordionData.map((item, index) => {
          const isActive = activeTab === index;

          return (
            <li
              key={index}
              className="border mb-5 last:mb-0 rounded-md shadow-md"
            >
              <div className="group">
                <summary
                  onClick={() =>
                    setActiveTab(isActive?null:index)
                  } // Toggle the active state
                  className="flex bg-gray-900 rounded-md items-center gap-3 px-4 py-3 font-medium hover:cursor-pointer"
                >
                  <svg
                    className={`w-5 h-5 text-white font-medium transition ${
                      isActive ? "rotate-90" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                    ></path>
                  </svg>
                  <span className="font-medium text-white text-xl">
                    {item.title}
                  </span>
                </summary>
               <article
                  className={`px-4 pb-4 ${isActive?"block":"hidden"}`}
                >
                  <ul className="p-6">
                    {item?.links?.map((link, idx) => (
                      <li
                        key={idx}
                        className="mb-1 last:mb-0 text-blue-700 hover:text-blue-600 font-medium text-md"
                      >
                        <a href="#">{link.name}</a>
                      </li>
                    ))}
                  </ul>
                </article>
              </div>
            </li>
          );
        })}
      </ul>

      <NewFooter />
    </>
  );
};

export default Investors;
