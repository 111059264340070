import { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import NewFooter from "../footer";
import LOCAL_IMAGES from "../utils/localImages";
import Banner from "../../components/banners/Banner";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { Helmet } from "react-helmet";

const CustomDesign = () => {
  const params = useParams();
  const location = useLocation();
  const [singleSalonDetail, setSingleSalonDetail] = useState({});
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const apiKey = process.env.REACT_APP_MAP_API_KEY;

  
  const google = window.google;
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await axios.get(
          `https://smartsalon.in/api/getSingleSalonDetail?id=${params?.id}`
          //  `https://prosaloncart.com/user/getSingleProductData?id=${shortUrl}`
        );
        const data = response.data.data;
        
        setSingleSalonDetail(data);
        setLatitude(data?.latitude);
        setLongitude(data?.longitude);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching salon details:", error);
        setIsLoading(false);
      }
    }
    fetchAPI();
  }, [params.id]);

  // useEffect(() => {
  //   
  // }, [latitude, longitude]);

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: latitude,
    lng: longitude,
  };
   
  return (
    <>

<Helmet>

       <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-KDRLNKTM');
          `}
        </script>


        {/* Google Tag Manager (noscript) */}
         <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KDRLNKTM"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript>
      </Helmet>
   
    <div className="flex flex-col items-center">
      <div className="h-full w-full">
        {singleSalonDetail.name == "Smart Salon" && (
          <Banner image={LOCAL_IMAGES.proplussalon} />
        )}
        {singleSalonDetail.name == "Monsoon Pro" && (
          <Banner image={LOCAL_IMAGES.monsoonprosalon} />
        )}
        {singleSalonDetail.name == "Monsoon" && (
          <Banner image={LOCAL_IMAGES.monsoonsalon} />
        )}
      </div>

      <h2 className="text-center my-4 md:my-9 px-3 font-bold font-[Montserrat]   text-xl md:text-3xl ">
        {singleSalonDetail?.address}{" "}
        <span className="font-medium">{singleSalonDetail?.address2}</span>
      </h2>

      <div className="flex flex-col md:flex-row mt-4 md:mt-8 space-y-4 md:space-y-0 md:space-x-8 px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12">
        <div className="flex-1">
          <p className="font-bold text-lg  md:text-2xl">
            Best Salon in {singleSalonDetail?.address}{" "}
            <span className="font-bold">{singleSalonDetail?.address2}</span>
          </p>
          <p className="mt-4 text-sm md:text-base">
            {`Looking for a rejuvenating salon experience in the heart of `}
            <span className="font-bold">{singleSalonDetail?.address2}</span>
            {`? Look no further! Smart salon Salon is your go-to destination for top-notch beauty and grooming services that will leave you feeling and looking your best. We’re proud to be the premier salon in `}
            <span className="font-bold">{singleSalonDetail.address2}</span>
            {`, and we’re conveniently located to serve you, whether you’re a local resident or just passing through.`}
          </p>
          <p className="mt-4 text-sm md:text-base">
            <span className="font-bold">Expert Stylists:</span>
            {` Our highly skilled and experienced stylists are dedicated to making your salon visit in `}
            <span className="font-bold">{singleSalonDetail?.address2}</span>
            {` a memorable one. They stay updated with the latest trends in hair, skincare, and beauty to provide you with cutting-edge services.`}
          </p>
          <p className="mt-4 text-sm md:text-base">
            <span className="font-bold">Premium Products:</span>
            {` We use only the finest, salon-quality products to ensure that your hair and skin receive the care they deserve. Our product range includes some of the most trusted names in the beauty industry. Best salon in `}
            <span className="font-bold">{singleSalonDetail?.address2}</span>
          </p>
        </div>

        <img
          src={
           singleSalonDetail?.images?.length>0? singleSalonDetail?.images[0]: LOCAL_IMAGES.salon
          }
          className="w-full md:w-1/2 aspect-[16/9] object-cover"
          alt="smart salon"
        />
      </div>

      <div className="flex flex-col md:flex-row mt-8 space-y-4 md:space-y-0 md:space-x-8 px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12">
        <div className="flex-1">
          {isLoading && latitude && longitude ? (
            <LoadScript googleMapsApiKey={apiKey}>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={15}
              >
                <Marker position={center} />
              </GoogleMap>
            </LoadScript>
          ) : (
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3504.6701034106613!2d77.267363!3d28.549634!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce24a702d1c55%3A0x9a5500a8d4eee992!2sMonsoon%20Salon%20Head%20Office!5e0!3m2!1sen!2sin!4v1695618174537!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          )}
        </div>

        <div className="flex-1 ">
          <p className="text-lg md:text-2xl font-bold">
            {`Hair Salon in `}
            <span className="font-bold">{singleSalonDetail?.address2}</span>
          </p>
          <p className="mt-4 text-sm md:text-base">
            {` Discover the difference of a salon that cares about your beauty and well-being. Schedule an appointment with us today and experience the excellence that has made us the top choice for those seeking a `}
            <span className="font-bold">{singleSalonDetail?.address2}</span>
            {`. Let us enhance your natural beauty and provide you with a refreshing escape from the hustle and bustle of daily life`}
          </p>
          <p className="mt-4 text-sm md:text-base">
            {`We understand that convenience is key, which is why we’re proud to be the salon that’s “near me” for many residents and visitors in  `}
            <span className="font-bold">{singleSalonDetail?.address2}</span>
            {`. Our central location makes it easy for you to stop by for a quick touch-up or a full day of pampering.`}
          </p>
        </div>
      </div>
      <div className="my-6 md:my-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-10">
          <div className="flex flex-col p-4 items-center justify-center bg-gray-100 border shadow-md py-12 px-3">
            <IoMdMail className="text-[25px] md:text-[30px]" />
            <h2 className="text-2xl font-bold mt-3 mb-1">Email</h2>

            <span>
              <a
                href="mailto:enquiry@theprofessionalworld.com"
                className="text-gray-600 ml-1"
              >
                enquiry@theprofessionalworld.com
              </a>
            </span>
          </div>
          <div className="flex flex-col p-4 items-center justify-center bg-gray-100 border shadow-md py-12 px-3">
            <FaPhoneAlt className="text-[25px] md:text-[30px]" />
            <h2 className="text-2xl font-bold mt-3 mb-1">Phone</h2>
            <span className="text-base font-normal">
              <a
                href={`tel:${singleSalonDetail?.phoneNumber}`}
                className="text-gray-600 ml-1"
              >
                {singleSalonDetail?.phoneNumber}
              </a>
            </span>
          </div>
          <div className="flex flex-col p-4 items-center justify-center bg-gray-100 border shadow-md py-12 px-3 ">
            <FaWhatsapp className="text-[25px] md:text-[30px]" />
            <h2 className="text-2xl font-bold mt-3 mb-1">Whatsapp</h2>

            <span>
              <a
                href={`https://wa.me/${singleSalonDetail?.phoneNumber}`}
                className="text-gray-600 ml-1 "
                style={{ textAlign: "center", alignItems: "center" }}
              >
                Click Here to Chat
              </a>
            </span>
          </div>
        </div>
      </div>

      <NewFooter />
    </div>
    </>
  );
};

export default CustomDesign;
