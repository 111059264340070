import { GoogleMap, InfoWindow, MarkerF } from "@react-google-maps/api";



const containerStyle = {
    width: '100%',
    height: '100%'
};

const indiaBounds = {
    north: 37.84,
    south: 8.4,
    east: 97.4,
    west: 68.1,
};
const MapComponent = ({center ,results,setActiveMarker,activeMarker,handleRedirect}) => {
    if (!center) return null; 
  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        options={{
          restriction: { latLngBounds: indiaBounds, strictBounds: false },
        }}
      >
        {results?.map((marker, index) => {
          return (
            <MarkerF
              key={index}
              position={{
                lat: marker?.geoLocation[1],
                lng: marker?.geoLocation[0],
              }}
              onClick={() => setActiveMarker(index)}
            >
              {activeMarker === index && (
                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                  <div>
                    <h2>Smart Salon</h2>
                    <p>{marker?.address}</p>
                    <p>{marker?.address2}</p>
                    <p>{marker?.stateName}</p>
                    <p>{marker?.phoneNumber}</p>
                    <button
                      onClick={() => handleRedirect(marker)}
                      style={{
                        background: "black",
                        color: "white",
                        width: 120,
                        borderRadius: 8,
                        padding: 5,
                        marginTop: 5,
                      }}
                    >
                      Go to Location
                    </button>
                  </div>
                </InfoWindow>
              )}
            </MarkerF>
          );
        })}
      </GoogleMap>
    </>
  );
};

export default MapComponent;
